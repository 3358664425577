@font-face {
  font-family: "Computer Modern";
  src: url("http://mirrors.ctan.org/fonts/cm-unicode/fonts/otf/cmunss.otf");
}

@font-face {
  font-family: "Computer Modern";
  src: url("http://mirrors.ctan.org/fonts/cm-unicode/fonts/otf/cmunsx.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Computer Modern";
  src: url("http://mirrors.ctan.org/fonts/cm-unicode/fonts/otf/cmunsi.otf");
  font-style: italic, oblique;
}

@font-face {
  font-family: "Computer Modern";
  src: url("http://mirrors.ctan.org/fonts/cm-unicode/fonts/otf/cmunbxo.otf");
  font-weight: bold;
  font-style: italic, oblique;
}

/* 
body {
  font-family: "Computer Modern", sans-serif;
} */

* {
  padding: 0%;
  margin: 0;
  box-sizing: border-box;
  font-size: 20px;
}

.img-container {
  display: flex;
  justify-content: center;
  background: #ededed;
  padding: 20px 0;
}

.avatar_img {
  border-radius: 50%;
  background-color: grey;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.content-container h1 {
  font-size: 25px;
}

.content-container :first-child {
  margin-top: 5px;
}

.about-container {
  max-width: 1200px;
  margin: 35px auto;
}

.about-contents h2 {
  margin-bottom: 15px;
}

.about-contents p {
  line-height: 1.5;
  text-align: justify;
}

.about-contents ul {
  padding-left: 35px;
}

.first-section {
  margin-bottom: 15px;
}

.links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.links>div>a>span {
  background-color: #343a40;
  margin: 0 5px;
  color: white;
  border-radius: 10px;
  padding: 3px 8px;
  font-size: 18px;
  cursor: pointer;
}

.publications {
  max-width: 1200px;
  margin: 0px auto;
  margin-bottom: 10px;
}

.publications-container {
  background-color: white;
  padding: 18px 20px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  margin-top: 10px;
}

.publications-container .links {
  justify-content: flex-start;
  color: #868e96 !important;
}

@media only screen and (max-width: 500px) {
  .publications-container .links {
    display: block;
  }

  .publications-container .links span {
    display: block;
    margin: 10px 0;

    width: 30%;
    text-align: center;
  }
}

.publications-container .links span {
  background-color: #868e96 !important;
}

.publications-container h4.paper-award {
  color: #d01919;
}

.publications-container a {
  text-decoration: none !important;
}

.publications-container .link-name {
  margin-top: 30px;
}

.publications-container h3 {
  margin: 5px 0;
}

.publications-container p {
  color: black;
}

.publication-name {
  max-width: 1200px;
  margin: 5px auto;
}

.abstract {
  margin-top: 15px;
}

.Collapsible__trigger {
  cursor: pointer;
  font-weight: 600;
}

.Collapsible__contentInner {
  margin-top: 10px;
}

.Collapsible__contentInner p {
  color: black !important;
}

.Collapsible__trigger {
  cursor: pointer !important;
}

.links a {
  text-decoration: none !important;
}

.paper-link {
  color: #007bff;
}

.author-name {
  margin-bottom: 10px;
}

/* experience */
.experience-heading {
  max-width: 1200px;
  margin: 10px auto;
}

.experience {
  max-width: 1200px;
  margin: 10px auto;
}

.experience-container {
  background-color: white;
  padding: 18px 20px;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.experience-container h3 {
  text-align: center;
  padding-top: 0px;
}

.experience-heading ::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 18%;
  padding-top: 7px;
  border-bottom: 1px solid black;
}

.heading-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.experience-location {
  text-align: end;
  margin-top: 5px;
}

.experience-bullets>ul>li {
  list-style-type: circle;
}

.sub-bullets {
  margin-left: -25px;
}

ul.sub-bullets>li {
  list-style-type: "- ";
}

.right {
  font-size: 18px;
  text-align: right;
  font-style: italic;
}

.left {
  font-size: 18px;
  text-align: left;
  font-style: italic;
}

.author-name-first {
  font-weight: bold;
}

.cool-link {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.cool-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: rgb(5, 181, 194);
  transition: width 0.3s;
}

.cool-link:hover::after {
  width: 100%;
  transition: width 0.3s;
}

.Collapsible__contentOuter {
  font-weight: 500;
}





div.paper-overview img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}